// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add your styles here */

body {
  font-family: "Poppins", sans-serif;
}

nav {
  max-width: var(--max-width);
  margin: auto;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.nav__logo {
  max-width: 150px;
}

.nav__links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.link a {
  position: relative;
  padding-bottom: 0.75rem;
  color: var(--white);
}

.link a::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  transition: 0.3s;
}

.link a:hover::after {
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/style.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,OAAO;EACP,SAAS;EACT,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["/* Add your styles here */\n\nbody {\n  font-family: \"Poppins\", sans-serif;\n}\n\nnav {\n  max-width: var(--max-width);\n  margin: auto;\n  padding: 2rem 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 2rem;\n}\n\n.nav__logo {\n  max-width: 150px;\n}\n\n.nav__links {\n  list-style: none;\n  display: flex;\n  align-items: center;\n  gap: 3rem;\n}\n\n.link a {\n  position: relative;\n  padding-bottom: 0.75rem;\n  color: var(--white);\n}\n\n.link a::after {\n  content: \"\";\n  position: absolute;\n  height: 2px;\n  width: 0;\n  left: 0;\n  bottom: 0;\n  background-color: var(--secondary-color);\n  transition: 0.3s;\n}\n\n.link a:hover::after {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
